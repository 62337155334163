import { Interpolation, css } from 'styled-components'

type CssParams = Parameters<typeof css>

function generateMediaQueryFn(deviceWidth: string) {
  // We need to use `object` to match styled-components
  // eslint-disable-next-line @typescript-eslint/ban-types
  return function <Props extends object>(
    ...args: [CssParams['0'], ...Interpolation<Props>[]]
  ) {
    return css`
      @media (min-width: ${deviceWidth}) {
        ${css<Props>(...args)};
      }
    `
  }
}

const media = {
  xl: generateMediaQueryFn('1440px'),
  lg: generateMediaQueryFn('1170px'),
  tablet: generateMediaQueryFn('1024px'),
  md: generateMediaQueryFn('768px'),
  sm: generateMediaQueryFn('412px'),
}

export default media

import React, { FC, useEffect, useState } from 'react'
import { Link } from 'gatsby'
import { NavBottom, NavBottomInner, DL, DLService } from '../styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import useAccordion from '@/hooks/useAccordion'
import NavItem from '../Items/NavItem'
import { data as hardcodedContent } from '../data'

const Service: FC<{ active: boolean }> = ({ active }): JSX.Element => {
  const {
    penetrationTesting,
    securityAssessment,
    applicationSecurity,
    adversarySimulation,
  } = hardcodedContent.services

  const [height, handleClick] = useAccordion(true)

  // TODO(HW13) Control all nav items via Contentful
  return (
    <DLService {...{ height }} data-expanded={height == 0} data-active={active}>
      <dt>
        <Link to="/services/">By Service</Link>
        <span onClick={handleClick}></span>
      </dt>
      <dd>
        <React.Fragment>
          <ul>
            <li className="heading">Penetration Testing</li>
            {penetrationTesting.map((service, index) => (
              <NavItem
                key={index}
                slug={service.slug as string}
                title={service.serviceName as string}
                subheading={service.serviceSubheading as string}
                basePath="/services"
              />
            ))}
          </ul>
          <ul>
            <li className="heading">Adversary Simulation</li>
            {adversarySimulation.map((service, index) => (
              <NavItem
                key={index}
                slug={service.slug as string}
                title={service.serviceName as string}
                subheading={service.serviceSubheading as string}
                basePath="/services"
              />
            ))}
            <li></li>
            <li></li>
          </ul>
          <ul>
            <li className="heading">Application Security</li>
            {applicationSecurity.map((service, index) => (
              <NavItem
                key={index}
                slug={service.slug as string}
                title={service.serviceName as string}
                subheading={service.serviceSubheading as string}
                basePath="/services"
              />
            ))}
            <li></li>
            <li></li>
          </ul>
          <ul>
            <li className="heading">Security Assessment</li>
            {securityAssessment.map((service, index) => (
              <NavItem
                key={index}
                slug={service.slug as string}
                title={service.serviceName as string}
                subheading={service.serviceSubheading as string}
                basePath="/services"
              />
            ))}
            <li>
              <Link to="/packetlabs-portal/">
                <p className="title">Packetlabs Portal</p>
                <aside>
                  <p className="aside">Explore a more interactive pentest</p>
                </aside>
              </Link>
            </li>
          </ul>
        </React.Fragment>
      </dd>
    </DLService>
  )
}

const Role: FC<{ active: boolean }> = ({ active }): JSX.Element => {
  const { roles } = hardcodedContent
  const matches = useMediaQuery('(min-width:768px)', { noSsr: true })
  const [height, handleClick] = useAccordion(matches)
  const items = roles.map((role, index) => {
    const { roleTitle, slug } = role
    return (
      <NavItem
        key={index}
        title={roleTitle as string}
        slug={slug as string}
        basePath="/roles"
      />
    )
  })
  useEffect(() => {
    if (matches && height == 0) {
      handleClick()
    }
  }, [matches, height, handleClick])
  return (
    <DL height={height} data-expanded={height == 0} data-active={active}>
      <dt>
        <Link to="/roles/">By Role</Link>
        <span onClick={handleClick}></span>
      </dt>
      <dd>
        <ul data-columns={Math.ceil(items.length / 4)}>{items}</ul>
      </dd>
    </DL>
  )
}

const Industry: FC<{ active: boolean }> = ({ active }): JSX.Element => {
  const { industries } = hardcodedContent
  const matches = useMediaQuery('(min-width:768px)', { noSsr: true })
  const [height, handleClick] = useAccordion(matches)
  const items = industries.map((industry, index) => {
    const { industryName, slug } = industry
    return (
      <NavItem
        key={index}
        title={industryName as string}
        slug={slug as string}
        basePath="/industries"
      />
    )
  })
  useEffect(() => {
    if (matches && height == 0) {
      handleClick()
    }
  }, [matches, height, handleClick])
  return (
    <DL height={height} data-expanded={height == 0} data-active={active}>
      <dt>
        <Link to="/industries/">By Industry</Link>
        <span onClick={handleClick}></span>
      </dt>
      <dd>
        <ul data-columns={Math.ceil(items.length / 4)}>{items}</ul>
      </dd>
    </DL>
  )
}

const SolutionsSubNavigation: FC<{
  opened?: boolean
  animate: boolean
}> = ({ opened = false, animate }): JSX.Element => {
  const [openTab, setOpenTab] = useState(0)
  const navs = ['Service', 'Role', 'Industry']
  const handleClick = (i: number) => {
    if (i !== openTab) setOpenTab(i)
  }

  const links = ['services', 'roles', 'industries']
  return (
    <NavBottom data-opened={opened}>
      <NavBottomInner>
        <ul className="left">
          <li>
            <h3>Solutions</h3>
          </li>

          <li className="copy">
            <p>
              Our solutions are geared to one key purpose - strengthening your
              security posture. You can rest easy knowing our comprehensive
              testing methodologies tackle hard-to-find vulnerabilities and
              demonstrate their potential impact.
            </p>
          </li>

          <li className="bottom">
            <Link to={`/${links[openTab]}/`} className="button">
              Explore Solutions
            </Link>
          </li>
        </ul>

        <div className={`right ${animate ? 'animate' : ''}`}>
          <ul className="tab">
            {navs.map((text, i) => (
              <li
                key={i}
                data-active={i === openTab}
                onClick={() => handleClick(i)}
              >
                {text}
              </li>
            ))}
          </ul>

          <Service active={openTab == 0} />

          <Role active={openTab == 1} />

          <Industry active={openTab == 2} />
        </div>
      </NavBottomInner>
    </NavBottom>
  )
}

export default SolutionsSubNavigation

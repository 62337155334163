import React from 'react'
import type { FC } from 'react'
import { Link } from 'gatsby'
import useMediaQuery from '@mui/material/useMediaQuery'
import { HubspotForm } from '../ContactForm/hubspot'
import {
  FooterWrapper,
  FooterBottom,
  FooterTop,
  AICPA,
  ClutchLogo,
  CrestLogo,
  FooterLinks,
  CyberRightNowLogo,
} from './styles'

// TODO(HW13): Get this data from Contentful
const Footer: FC = (): JSX.Element => {
  const greaterThanTablet = useMediaQuery('(min-width:768px)')

  const SocialMedias: FC = (): JSX.Element => {
    if (greaterThanTablet) {
      return (
        <ul className="pc">
          <li>
            <a
              href="https://www.linkedin.com/company/packetlabs-ltd-"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={`/icons/pc-linkedin.svg`}
                alt="linkedin"
                width="17"
                height="17"
              />
              LinkedIn
            </a>
          </li>
          <li>
            <a
              href="https://twitter.com/pktlabs"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={`/icons/pc-twitter.svg`}
                alt="twitter"
                width="17"
                height="17"
              />
              Twitter
            </a>
          </li>
          <li>
            <a
              href="https://www.facebook.com/packetlabs/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={`/icons/pc-facebook.svg`}
                alt="facebook"
                width="17"
                height="17"
              />
              Facebook
            </a>
          </li>
        </ul>
      )
    }
    return (
      <ul className="sp">
        <li>
          <a
            href="https://www.facebook.com/packetlabs/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={`/icons/sp-facebook.svg`}
              alt="facebook"
              width="37"
              height="37"
            />
          </a>
        </li>
        <li>
          <a
            href="https://www.linkedin.com/company/packetlabs-ltd-"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="/icons/sp-linkedin.svg"
              alt="linkedin"
              width="37"
              height="37"
            />
          </a>
        </li>
        <li>
          <a
            href="https://twitter.com/pktlabs"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="/icons/sp-twitter.svg"
              alt="twitter"
              width="37"
              height="37"
            />
          </a>
        </li>
      </ul>
    )
  }

  return (
    <FooterWrapper>
      <FooterTop>
        <div className="inner">
          <CyberRightNowLogo>
            <a
              href="https://chamber.ca/council/cyber-right-now/"
              target="_blank"
              rel="noreferrer"
            >
              <figure>
                <img
                  src={`/img/cyber-right-now.png`}
                  alt="Canadian Chamber of Commerce - Cyber Right Now"
                  width="152"
                  height="152"
                  loading="lazy"
                />
              </figure>
            </a>
          </CyberRightNowLogo>
          <CrestLogo>
            <a
              href="https://www.crest-approved.org/member_companies/packetlabs/"
              target="_blank"
              rel="noreferrer"
            >
              <figure>
                <img
                  src={`/img/crest-color.png`}
                  alt="CREST"
                  width="152"
                  height="152"
                  loading="lazy"
                />
              </figure>
            </a>
          </CrestLogo>
          <ClutchLogo>
            <a
              href="https://clutch.co/profile/packetlabs"
              target="_blank"
              rel="noreferrer"
            >
              <figure>
                <img
                  src={`/img/clutch2023.png`}
                  alt="Clutch Company 2023"
                  width="152"
                  height="152"
                  loading="lazy"
                />
              </figure>
            </a>
          </ClutchLogo>
          <AICPA>
            <Link to="/posts/soc-2-certification/">
              <figure>
                <img
                  src={`/img/aicpa.png`}
                  alt="AICPA SOC 2"
                  width="152"
                  height="152"
                  loading="lazy"
                />
              </figure>
            </Link>
          </AICPA>
          <FooterLinks>
            <ul className="above">
              <li>
                <p className="logo">
                  <img
                    src={`/img/logo.svg`}
                    alt="Packetlabs"
                    width="156"
                    height="36"
                  />
                </p>
              </li>
              <li className="address">
                <ul>
                  <li>
                    <strong>Toronto | HQ</strong>
                  </li>
                  <li>401 Bay Street, Suite 1600</li>
                  <li>Toronto, Ontario, Canada</li>
                  <li>M5H 2Y4</li>
                </ul>
              </li>
              <li className="address">
                <ul>
                  <li>
                    <strong>San Francisco | HQ</strong>
                  </li>
                  <li>580 California Street, 12th floor</li>
                  <li>San Francisco, CA, USA</li>
                  <li>94104</li>
                </ul>
              </li>
              <li className="input">
                <HubspotForm
                  formId="e70b1fbb-54a3-422d-a9c8-6ad0d494209d"
                  domId="footer-hubspot-form"
                />
              </li>
            </ul>
            <ul className="center">
              <li>
                <ul className="left">
                  <li>
                    <Link to="/contact-us/">Contact Us</Link>
                  </li>
                  <li>
                    <Link to="/learn/">Learn</Link>
                  </li>
                  <li>
                    <Link to="/services/">Services</Link>
                  </li>
                  <li>
                    <Link to="/packetlabs-portal/">Packetlabs Portal</Link>
                  </li>
                </ul>
              </li>
              <li>
                <ul className="right">
                  <li>
                    <Link to="/partner-program/">Partner Program</Link>
                  </li>
                  <li>
                    <a href="/careers/">Careers</a>
                  </li>
                  <li>
                    <Link to="/why-packetlabs/">About</Link>
                  </li>
                  <li>
                    <Link to="/faq/">FAQ</Link>
                  </li>
                </ul>
              </li>
            </ul>
            <div className="below">
              <SocialMedias />
            </div>
          </FooterLinks>
        </div>
      </FooterTop>
      <FooterBottom>
        <div className="inner">
          <ul>
            <li>
              <Link to="/privacy-policy/">Privacy Policy</Link>
            </li>
            <li>
              <Link to="/">Terms of Service</Link>
            </li>
          </ul>
          <p>
            &copy; {new Date().getFullYear()} Packetlabs. All rights reserved.
          </p>
        </div>
      </FooterBottom>
    </FooterWrapper>
  )
}

export default Footer

import React from 'react'
import type { GatsbyBrowser } from 'gatsby'
import { ThemeProvider } from 'styled-components'

import Nav from '@/components/Nav'
import Footer from '@/components/Footer'
import theme from '@/styles/theme'
import GlobalStyle from '@/styles/GlobalStyle'

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({
  element,
}) => {
  return (
    <>
      <GlobalStyle />
      <Nav />
      {element}
      <Footer />
    </>
  )
}

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({
  element,
}) => {
  return <ThemeProvider theme={theme}>{element}</ThemeProvider>
}

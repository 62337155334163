import { useState, useEffect, useCallback } from 'react'
import useToggle from '@/hooks/useToggle'

const useAccordion = (
  opened: boolean,
  defaultHight = 1600
): [number, () => void] => {
  const [height, setHeight] = useState(0)
  const [value, toggleValue] = useToggle(opened)

  const handleClick = useCallback(() => {
    toggleValue()
  }, [toggleValue])

  useEffect(() => {
    value ? setHeight(defaultHight) : setHeight(0)
  }, [value, defaultHight])

  return [height, handleClick]
}

export default useAccordion

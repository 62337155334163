import { styled, css } from 'styled-components'
import media from '@/styles/media'

export const NavWrapper = styled.nav`
  position: sticky;
  top: 0;
  z-index: 3;
  line-height: 1.5;
  background-color: transparent;
  transition: 0.2s;
  transform: translateZ(1px);
  height: 60px;
  &:hover {
    background-color: ${({ theme }) => theme.shades.prussian};
  }
  &.active {
    background-color: ${({ theme }) => theme.shades.prussian};
  }
`

export const NavMobile = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  ${media.md`
    display: none;
  `}
  ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    width: 90vw;
  }
`

export const CloseButtonContainer = styled.div`
  display: none;
  ${media.md`
    position: relative;
    margin: auto;
    width: min(90%, 1146px);
    text-align: right;
    display: block;
    z-index: 11;
    button {
      &:hover {
        cursor: pointer;
        color: ${({ theme }) => theme.colors.secondary.red};
      }
      color: ${({ theme }) => theme.shades.white};
      position: absolute;
      bottom: -42px;
      right: 0;
      margin-right: 12px;
      background: none;
      border: none;
      font-size: ${({ theme }) => theme.fontSizes.copy.medium};
      font-weight: ${({ theme }) => theme.fontWeights.bold};
      font-family: ${({ theme }) => theme.fonts.europaRegular};
    }
  `}
`

export const CloseOnClick = styled.div`
  display: none;
  ${media.md`
    display: block;
  `}
  position: absolute;
  inset: 0;
  width: 100vw;
  height: 100vh;
`

export const HamburgerMenu = styled.div<{ $active: boolean }>`
  display: flex;
  align-items: center;
  width: 8vw;
  height: 8vw;
  cursor: pointer;
  span.span {
    display: block;
    position: relative;
    width: 8vw;
    height: 3px;
    background: ${({ $active }) => ($active ? 'transparent' : '#ffffff')};
    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 8vw;
      height: 3px;
      border-radius: 4px;
      transition: all 0.3s ease-in-out;
      background-color: #ffffff;
    }
    &::before {
      top: ${({ $active }) => ($active ? 0 : '-10px')};
      transform: ${({ $active }) => ($active ? 'rotate(45deg)' : 'none')};
    }
    &::after {
      bottom: ${({ $active }) => ($active ? 0 : '-10px')};
      transform: ${({ $active }) => ($active ? 'rotate(-45deg)' : 'none')};
    }
  }
`

export const NavTop = styled.div`
  display: none;
  ${media.md`
    position: relative;
    z-index: 1;
    display: block;
    height: 100%;
  `}
`

export const NavTopInner = styled.div`
  margin: 0 auto;
  ${media.md`
    display: flex;
    justify-content: space-between;
    width: min(96%, 1364px);
    height: 100%;
    p.logo {
      margin-top: 10px;
    }
    ul {
      display: flex;
      align-items: center;
      font-size: ${({ theme }) => theme.fontSizes.copy.small};
      font-family: ${({ theme }) => theme.fonts.europaRegular};
      ${media.lg`
        font-size: ${({ theme }) => theme.fontSizes.copy.large};
      `}
      a, p {
        &:hover {
          color: ${({ theme }) => theme.colors.secondary.red};
          text-decoration: underline;
        }
      }
      li button {
        font-size: inherit;
        font-family: inherit;
        background: none;
        border: none;
        &:hover {
          color: ${({ theme }) => theme.colors.secondary.red};
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
    li+li {
      margin-left: 16px;

      ${media.lg`
        margin-left: 25px;
      `}
    }
  `}
`

export const ButtonBlock = styled.div`
  ${media.md`
    display: flex;
    align-items: center;
  `}
  a {
    ${media.md`
      font-size: ${({ theme }) => theme.fontSizes.copy.small};
    `}
    ${media.lg`
      font-size: ${({ theme }) => theme.fontSizes.copy.large};
    `}
    display: flex;
    &.line {
      display: none;
      @media (min-width: 1024px) {
        display: block;
      }
      text-decoration: underline;
      &:hover {
        color: ${({ theme }) => theme.colors.secondary.red};
      }
    }
    &.button {
      background-color: ${({ theme }) => theme.colors.secondary.red};
      align-items: center;
      justify-content: center;
      margin-left: 36.6px;
      transition: 0.3s;
      border-radius: ${({ theme }) => theme.borders.radius};
      ${media.md`
        width: 126px;
        height: 28.6px;
      `}
      &:hover {
        background-color: ${({ theme }) => theme.shades.white};
        color: ${({ theme }) => theme.colors.secondary.red};
      }
      p {
        font-weight: ${({ theme }) => theme.fontWeights.bold};
        line-height: 1;
      }
    }
  }
`

export const NavBottomWrapper = styled.div<{ $opened: boolean }>`
  position: relative;
  overflow: auto;
  max-height: ${({ $opened }) => ($opened ? '92vh' : 0)};
  transition: max-height 0.3s;
  background-color: ${({ theme }) => theme.shades.prussian};
  ${media.md`
    display: contents;
  `}
`

export const NavBottom = styled.section`
  position: relative;
  z-index: 10;
  background-color: ${({ theme }) => theme.shades.prussian};
  max-height: 0;
  transition: max-height 0;
  &[data-opened='true'] {
    max-height: 3000px;
    transition: max-height 1s;
  }
  ${media.md`
    overflow: auto;
    ${({ theme }) =>
      `background-image: linear-gradient(to bottom, transparent 30%, ${theme.shades.sherpaBlue}), linear-gradient(to top, transparent 30%, ${theme.shades.sherpaBlue}), url("https://images.ctfassets.net/yewqr8zk7e5s/2StSFRFPvF7ZZHnDqB1nLj/e7380efb18d9bc480980f9ed6ed8b542/services-silhouette.jpg?fm=webp&w=1024&h=768");`};
    background-repeat: no-repeat;
    background-size: cover;
    > * {
      position: relative;
    }
  `}
  div.extra-links {
    background-color: ${({ theme }) => theme.shades.sherpaBlue};
    ${media.md`
      display: none;
    `}
    ul.other, ul.buttons {
      display: grid;
      row-gap: 3.2vw;
      margin: 0 auto;
      padding: 6.4vw 0;
      width: 92%;
    }
    ul.other {
      a {
        font-size: ${({ theme }) => theme.fontSizes.copy.small};
      }
    }
    ul.buttons {
      a.line {
        font-size: ${({ theme }) => theme.fontSizes.copy.small};
        text-decoration: underline;
        line-height: 2.5;
      }
      a.button {
        border-radius: ${({ theme }) => theme.borders.radius};
        display: grid;
        place-items: center;
        width: ${(126 / 375) * 100}vw;
        height: ${(28 / 375) * 100}vw;
        font-size: ${({ theme }) => theme.fontSizes.copy.small};
        font-weight: ${({ theme }) => theme.fontWeights.bold};
        background-color: ${({ theme }) => theme.colors.secondary.red};
      }
    }
  }
`

export const NavBottomInner = styled.div`
  margin: 0 auto;
  width: 92%;
  ${media.md`
    display: flex;
    column-gap: 28px;
    position: relative;
    padding-top: 14px;
    padding-bottom: 24px;
    padding-right: 14px;
    width: min(90%, 1146px);
  `}
  ul.left {
    display: none;
    ${media.md`
      display: flex;
      flex-direction: column;
      padding-inline: 16px;
      max-width: 232px;
    `}
    h3 {
      font-size: ${({ theme }) => theme.fontSizes.headings.medium};
      font-weight: ${({ theme }) => theme.fontWeights.regular};
    }
    a {
      display: grid;
      border-radius: ${({ theme }) => theme.borders.radius};
      place-items: center;
      font-size: ${({ theme }) => theme.fontSizes.copy.small};
      ${media.lg`
        font-size: ${({ theme }) => theme.fontSizes.copy.medium};
      `}
      font-weight: ${({ theme }) => theme.fontWeights.bold};
      background-color: ${({ theme }) => theme.colors.secondary.red};
      transition: 0.3s;
      ${media.md`
        height: 36px;
      `};
      &:hover {
        background-color: #c5010c;
      }
    }
    li.copy {
      margin-top: 10px;
      font-size: ${({ theme }) => theme.fontSizes.copy.small};
      ${media.lg`
        font-size: ${({ theme }) => theme.fontSizes.copy.medium};
      `}
    }
    li.bottom {
      padding-top: 28px;
    }
  }
  div.right {
    flex-grow: 1;
  }
  ul.tab {
    display: none;
    ${media.md`
      display: flex;
    `}
    > li {
      padding: 12px 24px;
      font-weight: 600;
      cursor: pointer;
      &[data-active='true'] {
        color: ${({ theme }) => theme.colors.secondary.red};
        background-color: rgba(255, 255, 255, 0.9);
      }
    }
  }
`

const shared = css`
  ${media.md`
    display: none;
    &[data-active='true'] {
      display: block;
    }
    padding: 24px;
    color: ${({ theme }) => theme.colors.primary.darkCerulean};
    background-color: rgba(255, 255, 255, 0.9);
  `}
  a {
    &:hover {
      color: ${({ theme }) => theme.colors.secondary.red};
    }
  }
  dt {
    display: flex;
    justify-content: space-between;
    position: relative;
    padding-bottom: 0.6em;
    border-bottom: 2.4px solid #ffffff;
    font-size: ${({ theme }) => theme.fontSizes.copy.small};
    ${media.md`
      display: none;
    `}
    a {
      flex-shrink: 0;
    }
    span {
      position: relative;
      flex-grow: 1;
      cursor: pointer;
      ${media.md`
        display: none;
      `}
      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 4px;
        margin: auto 0;
        width: 8.6px;
        height: 8.6px;
        border-top: solid 2px #ffffff;
        border-left: solid 2px #ffffff;
        transition: 0.3s;
        transform: rotate(45deg);
      }
    }
  }
  &[data-expanded='true'] {
    span {
      &:after {
        transform: rotate(225deg);
      }
    }
  }
  dd {
    margin-top: 2vw;
    margin-bottom: 10vw;
    ${media.md`
      margin-top: unset;
      margin-bottom: 0;
    `}
  }
  ul {
    ${media.md`
      row-gap: 12px;
      column-gap: 16px;
    `}
  }
`

export const DL = styled.dl<{
  height: number
}>`
  ${shared};
  dd {
    overflow: auto;
    max-height: ${({ height }) => height}px;
    transition: max-height 0.3s;
  }
  ul {
    ${media.md`
      display: grid;
      grid-template-rows: repeat(4, 1fr);
      grid-template-columns: repeat(2, 1fr);
      row-gap: 12px;
      column-gap: 16px;
      &[data-columns='3'] {
        grid-template-columns: repeat(3, 1fr);
      }
      &[data-columns='4'] {
        grid-template-columns: repeat(4, 1fr);
      }
      &[data-columns='5'] {
        grid-template-columns: repeat(5, 1fr);
      }
      &[data-columns='6'] {
        grid-template-columns: repeat(6, 1fr);
      }
      &[data-columns='7'] {
        grid-template-columns: repeat(7, 1fr);
      }
      &[data-columns='8'] {
        grid-template-columns: repeat(8, 1fr);
      }

      margin-top: -3px;
    `}
    li {
      :first-of-type {
        margin-top: 4vw;

        ${media.md`
          margin-top: unset;
        `}
      }
      & + li {
        margin-top: 6vw;
        ${media.md`
          margin-top: unset;
        `}
      }
    }
    a {
      font-size: ${({ theme }) => theme.fontSizes.copy.small};
    }
  }
`
export const DLLearn = styled.dl<{ height: number }>`
  ${shared};
  dd {
    overflow: auto;
    width: 90%;
    max-height: ${({ height }) => height}px;
    transition: max-height 0.3s;

    ${media.md`
      display: grid;
      grid-gap: 15px;
      grid-template-columns: repeat(4, 1fr);
    `}
  }
  ul {
    ${media.md`
      display: contents;
    `}
  }
  li.heading {
    display: none;
    ${media.md`
    font-size: ${({ theme }) => theme.fontSizes.copy.small};
      display: block;
      text-decoration: underline;
      text-decoration-color: ${({ theme }) => theme.colors.secondary.red};
      text-underline-offset: 0.4em;
    `}
  }
  li:first-of-type {
    margin-top: 4vw;

    ${media.md`
      margin-top: unset;
    `}
  }
  li + li {
    margin-top: 6vw;

    ${media.md`
      margin-top: unset;
      margin-bottom: 10px
    `}
  }
  p.title {
    font-size: ${({ theme }) => theme.fontSizes.copy.small};
  }
  aside {
    ${media.md`
      margin-top: 0.3em;
    `}
  }
  p.aside {
    font-size: ${({ theme }) => theme.fontSizes.copy.small};
    color: #8093a7;
  }
  a {
    display: block;
    line-height: 1.2;
  }
`

export const DLService = styled.dl<{
  height: number
}>`
  ${shared};
  dd {
    overflow: auto;
    width: 90%;
    max-height: ${({ height }) => height}px;
    transition: max-height 0.3s;
    ${media.md`
      display: grid;
      grid-template-rows: auto repeat(4, 1fr);
      grid-template-columns: repeat(4, 1fr);
      grid-auto-flow: column;
      row-gap: 12px;
      column-gap: 16px;
      width: 100%;
    `}
  }
  ul {
    ${media.md`
      display: contents;
    `}
  }
  li.heading {
    display: none;
    ${media.md`
    font-size: ${({ theme }) => theme.fontSizes.copy.small};
      display: block;
      text-decoration: underline;
      text-decoration-color: ${({ theme }) => theme.colors.secondary.red};
      text-underline-offset: 0.4em;
    `}
  }
  li + li {
    margin-top: 6vw;
    ${media.md`
      margin-top: unset;
    `}
  }
  p.title {
    font-size: ${({ theme }) => theme.fontSizes.copy.small};
  }
  aside {
    ${media.md`
      margin-top: 0.4px;
    `}
  }
  p.aside {
    font-size: ${({ theme }) => theme.fontSizes.copy.small};
    color: #8093a7;
  }
`

export const DLGeneral = styled.dl<{
  height: number
}>`
  ${shared};
  dd {
    overflow: auto;
    width: 90%;
    max-height: ${({ height }) => height}px;
    transition: max-height 0.3s;
    ${media.md`
      display: flex;
      flex-direction: row;
      row-gap: 12px;
      column-gap: 2.5em;
      width: 100%;
    `}
  }
  ul {
    ${media.md`
      display: flex;
      flex-direction: column;
      flex: 1 1 0;
      row-gap: 1em;
    `}
  }
  li.heading {
    display: none;
    ${media.md`
    font-size: ${({ theme }) => theme.fontSizes.copy.small};
      display: block;
      text-decoration: underline;
      text-decoration-color: ${({ theme }) => theme.colors.secondary.red};
      text-underline-offset: 0.4em;
    `}
  }
  li + li {
    margin-top: 6vw;
    ${media.md`
      margin-top: unset;
    `}
  }
  p.title {
    font-size: ${({ theme }) => theme.fontSizes.copy.small};
  }
  aside {
    ${media.md`
      margin-top: 0.4px;
    `}
  }
  p.aside {
    font-size: ${({ theme }) => theme.fontSizes.copy.small};
    color: #8093a7;
  }
`

export const DLResources = styled(DLGeneral)``

// organization of theme inspired by this article (official docs lacking):
// https://dev.to/aromanarguello/how-to-use-themes-in-styled-components-49h

// colors were named using this tool:
// https://www.color-blindness.com/color-name-hue/
// naming colors this way is more meaningful/descriptive than just 'lightBlue', etc.

const theme = {
  borders: {
    radius: '20px',
  },
  mediaQueries: {
    desktopHD: 'only screen and (max-width: 1920px)',
    desktopMedium: 'only screen and (max-width: 1680px)',
    desktopSmall: 'only screen and (max-width: 1440px)',
    laptop: 'only screen and (max-width: 1366px)',
    laptopSmall: 'only screen and (max-width: 1280px)',
    tabletLandscape: 'only screen and (max-width: 1024px)',
    tabletMedium: 'only screen and (max-width: 900px)',
    tabletPortrait: 'only screen and (max-width: 768px)',
    mobileXLarge: 'only screen and (max-width: 640px)',
    mobileLarge: 'only screen and (max-width: 576px)',
    mobileMedium: 'only screen and (max-width: 480px)',
    mobileSmall: 'only screen and (max-width: 415px)',
    mobileXSmall: 'only screen and (max-width: 375px)',
    mobileTiny: 'only screen and (max-width: 325px)',
  },
  shades: {
    white: '#fff',
    black: '#000',
    sherpaBlue: '#001A38',
    prussian: '#00274F',
  },
  greys: {
    whiteSmoke: '#e9e9e9',
    gainsboro: '#e4e4e4',
    silver: '#bcbcbc',
    dimGrey: '#666666',
    lynch: '#667688',
    lightSlate: '#808C9B',
  },
  colors: {
    primary: {
      iceberg: '#c6e0dd',
      columbiaBlue: '#b2f0ff',
      shamrock: '#2fc3b5',
      darkCerulean: '#0a4877',
      blueWhale: '#16223f',
    },
    secondary: {
      aliceBlue: '#f7fdff',
      gunPowder: '#4c4d5b',
      tangaroa: '#141a2b',
      whiteIce: '#ecf8f6',
      red: '#FD3842',
    },
  },
  gradients: {
    darkNavyBlue:
      'radial-gradient(67.36% 217.71% at 22.5% 38.7%, #242e47 0%, #12192a 100%)',
  },
  fonts: {
    europaBold: 'Europa, sans-serif',
    europaRegular: 'Europa, sans-serif',
  },
  fontWeights: {
    extraLight: 200,
    light: 300,
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
  },
  fontSizes: {
    copy: {
      xsmall: '0.75rem', // 12px
      small: '0.875rem', // 14px
      medium: '1rem', // 16px
      large: '1.125rem', // 18px
      xlarge: '1.5rem', // 24px
      xxlarge: '1.875rem', // 30px
    },
    spCopy: {
      xsmall: '3.8vw',
      small: '4.2vw',
      medium: '5.4vw',
      large: '8vw',
      xlarge: '10.6vw',
      xxlarge: '1.875rem',
    },
    headings: {
      xxsmall: '1.25rem',
      xsmall: '1.5rem', // 24px
      small: '2.25rem', // 36px
      medium: '3rem', // 48px
      large: '3.5rem', // 56px
      xlarge: '4rem', // 64px,
      xxlarge: '4.5rem', // 72px,
    },
  },
  spacing: {
    wide: '2rem',
    narrow: '4.5rem',
    mobile: '1.5rem',
    mobileTopBottomSpacing: '7.5% 0',
    standardLineHeight: 1.5,
    headingLineHeight: 1.25,
  },
}

export default theme

import React, { useState, useEffect, FC } from 'react'
import { Link } from 'gatsby'
import useScrollTrigger from '@mui/material/useScrollTrigger'
import {
  NavWrapper,
  NavMobile,
  HamburgerMenu,
  NavTop,
  NavTopInner,
  ButtonBlock,
  NavBottomWrapper,
  CloseOnClick,
  CloseButtonContainer,
} from './styles'
import SolutionsSubNavigation from './Subnavigations/SolutionsSubNavigation'
import LearnSubNavigation from './Subnavigations/LearnSubNavigation'
import { useLocation } from '@reach/router'

const SOLUTIONS = 'solutions'
const LEARN = 'learn'

const Nav: FC = () => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 1,
  })
  const [mobileNavOpen, setMobileNavOpen] = useState(false)
  const [activeSubNav, setActiveSubNav] = useState<string | null>(null)
  const location = useLocation()
  useEffect(() => {
    setActiveSubNav(null)
    setMobileNavOpen(false)
  }, [location.pathname])
  return (
    <NavWrapper className={trigger ? 'active' : ''}>
      <NavMobile>
        <ul>
          <li>
            <p className="logo">
              <Link to="/">
                <img
                  src="/img/logo.svg"
                  alt="Packetlabs"
                  width="156"
                  height="36"
                />
              </Link>
            </p>
          </li>
          <li>
            <HamburgerMenu
              $active={mobileNavOpen}
              onClick={() => {
                setMobileNavOpen(!mobileNavOpen)
              }}
            >
              <span className="span"></span>
            </HamburgerMenu>
          </li>
        </ul>
      </NavMobile>
      <NavTop>
        <NavTopInner>
          <p className="logo">
            <Link to="/">
              <img
                src="/img/logo.svg"
                alt="Packetlabs"
                width="156"
                height="36"
              />
            </Link>
          </p>
          <ul>
            <li>
              <button
                onClick={() => {
                  if (activeSubNav === SOLUTIONS) {
                    setActiveSubNav(null)
                  } else {
                    setActiveSubNav(SOLUTIONS)
                  }
                }}
              >
                Solutions
              </button>
            </li>
            <li>
              <button
                onClick={() => {
                  if (activeSubNav === LEARN) {
                    setActiveSubNav(null)
                  } else {
                    setActiveSubNav(LEARN)
                  }
                }}
              >
                Learn
              </button>
            </li>
            <li>
              <Link to="/why-packetlabs/">Why Packetlabs</Link>
            </li>
            <li>
              <Link to="/partner-program/">Partner Program</Link>
            </li>
            <li>
              <Link to="/careers/">Careers</Link>
            </li>
          </ul>
          <ButtonBlock>
            <Link to="/get-quote/" className="line">
              Get a Quote
            </Link>
            <Link to="/contact-us/" className="button">
              <p>Contact Us</p>
            </Link>
          </ButtonBlock>
        </NavTopInner>
      </NavTop>
      <NavBottomWrapper $opened={mobileNavOpen}>
        {activeSubNav !== null ? (
          <CloseButtonContainer>
            <button
              aria-label="close"
              onClick={() => {
                setActiveSubNav(null)
              }}
            >
              ✕
            </button>
          </CloseButtonContainer>
        ) : null}
        <SolutionsSubNavigation
          opened={mobileNavOpen || activeSubNav === SOLUTIONS}
          animate={activeSubNav !== SOLUTIONS}
        />
        <LearnSubNavigation
          opened={mobileNavOpen || activeSubNav === LEARN}
          animate={activeSubNav !== LEARN}
        />
        {activeSubNav !== null ? (
          <CloseOnClick
            onClick={() => {
              setActiveSubNav(null)
            }}
          />
        ) : null}
      </NavBottomWrapper>
    </NavWrapper>
  )
}

export default Nav

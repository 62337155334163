import React, { useEffect, useCallback } from 'react'
import type { FC } from 'react'
import { useInView } from 'react-intersection-observer'

export const HubspotForm: FC<{
  portalId?: string
  formId?: string
  domId?: string
}> = ({
  portalId = '20040397',
  formId = '5da0bc1d-0662-4439-b634-7c42d14bf2b4',
  domId = 'hubspot-form',
}): JSX.Element => {
  const [element, inView] = useInView({
    threshold: 0,
    triggerOnce: true,
    rootMargin: '500px',
  })

  const createForm = useCallback(() => {
    // @ts-expect-error window object doesn't have hbspt as default
    if (window.hbspt) {
      // @ts-expect-error window object doesn't have hbspt as default
      window.hbspt.forms.create({
        portalId: portalId,
        formId: formId,
        target: `#${domId}`,
      })
    }
  }, [portalId, formId, domId])

  useEffect(() => {
    if (typeof window !== 'undefined' && inView) {
      // @ts-expect-error window object doesn't have hbspt as default
      if (!window.hbspt) {
        const script = document.createElement('script')
        script.src = 'https://js.hsforms.net/forms/v2.js'
        script.async = true
        script.setAttribute('defer', '')
        document.body.appendChild(script)
        script.addEventListener('load', () => {
          createForm()
        })
      } else {
        createForm()
      }
    }
    return () => window.removeEventListener('load', createForm)
  }, [createForm, inView])

  return <div id={domId} className="form" ref={element}></div>
}

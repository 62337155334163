import { styled } from 'styled-components'
import media from '@/styles/media'

export const FooterWrapper = styled.footer`
  position: relative;
  z-index: 1;
  font-family: ${({ theme }) => theme.fonts.europaRegular};
  img {
    border: 0;
    display: block;
    height: auto;
    max-width: 100%;
  }
`

export const AICPA = styled.div`
  position: absolute;
  top: 24px;
  right: 0;
  figure {
    width: 76px;
  }
  p {
    text-align: center;
    ${media.md`
      margin-top: -20px;
    `}
  }
`

export const ClutchLogo = styled.div`
  position: absolute;
  right: 8px;
  top: 100px;
  ${media.md`
    top: 24px;
    right: 86px;
  `}
  figure {
    width: 61px;
  }
`

export const CrestLogo = styled.div`
  position: absolute;
  right: 8px;
  top: 178px;
  ${media.md`
    top: 24px;
    right: 160px;
  `}
  figure {
    width: 61px;
  }
`

export const CyberRightNowLogo = styled.div`
  position: absolute;
  right: 0;
  top: 256px;
  ${media.md`
    top: 24px;
    right: 224px;
  `}
  figure {
    width: 70px;
  }
`

export const FooterTop = styled.div`
  min-height: 750px;
  padding-bottom: 12px;
  border-top: 1px solid #ffffff;
  ${media.md`
    min-height: 520px;
  `}
  div.inner {
    position: relative;
    margin: 0 auto;
    width: 90vw;
    ${media.md`
      width: min(96%, 1062px);
    `}
  }
`

export const FooterLinks = styled.div`
  ${media.md`
    display: flex;
    justify-content: space-between;
  `}
  ul.above, ul.center {
    a {
      &:hover {
        color: ${({ theme }) => theme.colors.secondary.red};
        text-decoration: underline;
      }
    }
  }
  ul.above {
    margin-top: 9vw;
    padding-top: 12vw;
    ${media.md`
      margin-top: 46px;
      padding-top: unset;
    `}
    li.text {
      margin-top: 8vw;
      font-size: 3.8vw;
      color: ${({ theme }) => theme.greys.lynch};
      ${media.md`
        margin-top: 20px;
        font-size: ${({ theme }) => theme.fontSizes.copy.small};
      `}
    }
    li.address {
      margin-top: 7.2vw;
      ${media.md`
        margin-top: 26px;
      `}
      ul {
        display: grid;
        row-gap: 12px;
        font-size: ${({ theme }) => theme.fontSizes.spCopy.small};
        li {
          strong {
            font-weight: ${({ theme }) => theme.fontWeights.semiBold};
          }
          ${media.md`
            font-size: ${({ theme }) => theme.fontSizes.copy.medium};
          `}
        }
      }
    }
    li.input {
      position: relative;
      margin-top: 7.2vw;
      ${media.md`
        margin-top: 26px;
      `}
      input {
        padding-left: 2vw;
        width: calc(100% - 2vw);
        height: 12.8vw;
        font-size: 16px;
        border: 1px solid #ffffff;
        ${media.md`
          padding-left: 16px;
          width: 350px;
          height: 48px;
        `}
        &::placeholder {
          color: ${({ theme }) => theme.greys.lightSlate};
          ${media.md`
            font-size: ${({ theme }) => theme.fontSizes.copy.medium};
          `}
        }
      }
      svg {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 4vw;
        margin-top: auto;
        margin-bottom: auto;
        ${media.md`
          right: 16px;
        `}
        path {
          fill: #ffffff;
        }
      }
    }
  }
  ul.center {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-top: 8.2vw;
    ${media.md`
      margin-top: 108px;
      width: min(24vw, 284px);
    `}
    ${media.lg`
      width: 284px;
    `}
  }
  ul.left,
  ul.right {
    display: grid;
    row-gap: 4vw;
    ${media.md`
      row-gap: 20px;
    `}
    a {
      font-size: 4.2vw;
      ${media.md`
        font-size: ${({ theme }) => theme.fontSizes.copy.medium};
      `}
    }
  }
  div.below {
    margin-top: 10vw;
    ${media.md`
      margin-top: 130px;
      margin-right: min(4vw, 30px);
    `}
  }
  ul.sp {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 50%;
  }
  ul.pc {
    display: grid;
    row-gap: 12px;
    li {
      display: flex;
      align-items: center;
    }
    a {
      display: flex;
      transition: 0.3s;
      &:hover {
        color: ${({ theme }) => theme.colors.secondary.red};
        text-decoration: underline;
      }
    }
    img {
      display: inline-block;
      margin-right: 12px;
    }
  }
`

export const FooterBottom = styled.div`
  height: 22vw;
  color: ${({ theme }) => theme.greys.dimGrey};
  background-color: ${({ theme }) => theme.shades.black};
  ${media.md`
    height: 60px;
  `}
  div.inner {
    margin: 0 auto;
    padding-top: 4vw;
    width: 90vw;
    ${media.md`
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row-reverse;
      padding-top: 0;
      width: min(96%, 1100px);
      height: 100%;
      font-size: ${({ theme }) => theme.fontSizes.copy.small};
    `}
    ul {
      display: flex;
      justify-content: space-between;
    }
    li + li {
      ${media.md`
        margin-left: 24px;
      `}
    }
    p {
      text-align: center;
      margin-top: 4vw;
      ${media.md`
        margin-top: 0;
      `}
    }
  }
`

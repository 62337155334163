import React, { FC, useState, useEffect } from 'react'
import { Link } from 'gatsby'
import {
  NavBottom,
  NavBottomInner,
  DLLearn,
  DLGeneral,
  DLResources,
} from '../styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import useAccordion from '@/hooks/useAccordion'
import { data as hardcodedContent } from '../data'
import { NavItemList } from '../Items/NavItem'

const General: FC<{
  active: boolean
}> = ({ active }): JSX.Element => {
  const data = hardcodedContent.learn.general
  const matches = useMediaQuery('(min-width:768px)', { noSsr: true })
  const [height, handleClick] = useAccordion(matches)

  useEffect(() => {
    if (matches && height == 0) {
      handleClick()
    }
  }, [matches, height, handleClick])

  return (
    <DLGeneral {...{ height }} data-expanded={height == 0} data-active={active}>
      <dt>
        <Link to="/learn/">General</Link>
        <span onClick={handleClick}></span>
      </dt>
      <dd>
        <React.Fragment>
          <ul>
            <li className="heading">{data.general.title}</li>
            <NavItemList basePath="/posts" list={data.general.data} />
          </ul>
          <ul>
            <li className="heading">{data.penetrationtesting.title}</li>
            <NavItemList
              basePath="/posts"
              list={data.penetrationtesting.data}
            />
          </ul>
          <ul>
            <li className="heading">{data.owasp.title}</li>
            <NavItemList basePath="/posts" list={data.owasp.data} />
          </ul>
          <ul>
            <li className="heading">{data.cybersecurity.title}</li>
            <NavItemList basePath="/posts" list={data.cybersecurity.data} />
          </ul>
        </React.Fragment>
      </dd>
    </DLGeneral>
  )
}

const Guides: FC<{
  active: boolean
}> = ({ active }): JSX.Element => {
  const { guides } = hardcodedContent.learn
  const matches = useMediaQuery('(min-width:768px)', { noSsr: true })
  const [height, handleClick] = useAccordion(matches)

  useEffect(() => {
    if (matches && height == 0) {
      handleClick()
    }
  }, [matches, height, handleClick])

  return (
    <DLLearn height={height} data-expanded={height == 0} data-active={active}>
      <dt>
        <Link to="/learn">Guides</Link>
        <span onClick={handleClick}></span>
      </dt>
      <dd>
        <React.Fragment>
          <ul>
            <NavItemList list={guides} basePath="/posts" />
            <li>
              <a
                href="https://contact.packetlabs.net/penetration-testing-buyers-guide"
                target="_blank"
                rel="noopener noreferrer"
              >
                <p className="title">{`Buyer's Guide to Pentesting`}</p>
                <aside>
                  <p className="aside">
                    The ultimate guide to successfully plan, scope and execute
                    your next penetration testing project
                  </p>
                </aside>
              </a>
            </li>
          </ul>
        </React.Fragment>
      </dd>
    </DLLearn>
  )
}

const ResourceList = (props: { list: { url: string; title: string }[] }) => {
  const items = props.list.map((resource, index) => (
    <li key={index}>
      <a href={resource.url} target="_blank" rel="noopener noreferrer">
        <p className="title">{resource.title}</p>
      </a>
    </li>
  ))
  return <>{items}</>
}

const Resources: FC<{
  active: boolean
}> = ({ active }): JSX.Element => {
  const { resources } = hardcodedContent.learn
  const matches = useMediaQuery('(min-width:768px)', { noSsr: true })
  const [height, handleClick] = useAccordion(matches)

  useEffect(() => {
    if (matches && height == 0) {
      handleClick()
    }
  }, [matches, height, handleClick])

  return (
    <DLResources
      {...{ height }}
      data-expanded={height == 0}
      data-active={active}
    >
      <dt>
        <Link to="/learn/">Resources</Link>
        <span onClick={handleClick}></span>
      </dt>
      <dd>
        <ul>
          <li className="heading">{resources.service.title}</li>
          <ResourceList list={resources.service.data} />
        </ul>

        <ul>
          <li className="heading">{resources.sample.title}</li>
          <ResourceList list={resources.sample.data} />
        </ul>

        <ul>
          <li className="heading">{resources.tools.title}</li>
          <ResourceList list={resources.tools.data} />
        </ul>
      </dd>
    </DLResources>
  )
}

const CaseStudies: FC<{
  active: boolean
}> = ({ active }): JSX.Element => {
  const { caseStudies } = hardcodedContent.learn
  const matches = useMediaQuery('(min-width:768px)', { noSsr: true })
  const [height, handleClick] = useAccordion(matches)

  useEffect(() => {
    if (matches && height == 0) {
      handleClick()
    }
  }, [matches, height, handleClick])
  return (
    <DLLearn height={height} data-expanded={height == 0} data-active={active}>
      <dt>
        <Link to="/learn/">Case Studies</Link>
        <span onClick={handleClick}></span>
      </dt>
      <dd>
        <React.Fragment>
          <ul>{<NavItemList list={caseStudies} basePath="/case-studies" />}</ul>
        </React.Fragment>
      </dd>
    </DLLearn>
  )
}

const LearnSubNavigation: FC<{
  opened?: boolean
  animate: boolean
}> = ({ opened = false, animate }) => {
  const [openTab, setOpenTab] = useState(0)
  const navs = ['Guides', 'Blogs', 'Resources', 'Case Studies']
  const handleClick = (i: number) => {
    if (i !== openTab) setOpenTab(i)
  }

  return (
    <NavBottom data-opened={opened}>
      <NavBottomInner>
        <ul className="left">
          <li>
            <h3>Learn</h3>
          </li>

          <li className="copy">
            <p>
              Our solutions are geared toward strengthening your security
              posture. Browse the best of our resources today to learn how our
              comprehensive testing methodologies tackle hard-to-find
              vulnerabilities.
            </p>
          </li>

          <li className="bottom">
            <Link to={`/learn/`} className="button">
              See All
            </Link>
          </li>
        </ul>

        <div className={`right ${animate ? 'animate' : ''}`}>
          <ul className="tab">
            {navs.map((text, i) => (
              <li
                key={i}
                data-active={i === openTab}
                onClick={() => handleClick(i)}
              >
                {text}
              </li>
            ))}
          </ul>

          <Guides active={openTab === 0} />
          <General active={openTab === 1} />
          <Resources active={openTab === 2} />
          <CaseStudies active={openTab === 3} />
        </div>
      </NavBottomInner>

      <div className="extra-links">
        <ul className="other">
          <li>
            <Link to="/why-packetlabs/">Why Packetlabs</Link>
          </li>
          <li>
            <Link to="/partner-program/">Partner Program</Link>
          </li>
          <li>
            <Link to="/careers/">Careers</Link>
          </li>
        </ul>

        <ul className="buttons">
          <li>
            <Link to="/get-quote/" className="line">
              Get a Quote
            </Link>
          </li>
          <li>
            <Link to="/contact-us/" className="button">
              <p>Contact Us</p>
            </Link>
          </li>
        </ul>
      </div>
    </NavBottom>
  )
}

export default LearnSubNavigation

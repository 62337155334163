// TODO(HW13): Move to Contentful
export const data = {
  learn: {
    guides: [
      {
        title: 'Guide to Penetration Testing',

        slug: 'guide-to-penetration-testing',

        subheading:
          'Everything you need to know to successfully plan, scope and execute your infrastructure pentests.',
      },
      {
        title: 'Guide to Ransomware Penetration Testing',

        slug: 'guide-to-ransomware-penetration-testing',

        subheading:
          'Everything you need to know to successfully plan, scope and execute your ransomware tests.',
      },
      {
        title: 'Guide to Objective-based Penetration Testing',

        slug: 'guide-to-objective-based-penetration-testing',

        subheading: 'Everything you need for OBPT.',
      },
      {
        title: 'Guide to Application Security Testing',

        slug: 'guide-to-application-security-testing',

        subheading:
          'Everything you need to know to successfully plan, scope and execute your application security tests.',
      },
      {
        title: 'Guide to Cloud Penetration Testing',

        slug: 'guide-to-cloud-penetration-testing',

        subheading:
          'Everything you need to protect your digital infrastructure against an ever-evolving threat landscape.',
      },
      {
        title: 'Guide to ICS/OT Security Assessments',

        slug: 'your-guide-to-ics-ot-cybersecurity-assessments',

        subheading:
          'Everything you need to know about ensuring the safety of your organization’s devices and systems.',
      },
    ],
    general: {
      general: {
        title: 'General',
        data: [
          {
            title: 'Penetration Testing Pricing',
            slug: 'penetration-testing-pricing',
          },
          {
            title: 'Choosing a Penetration Testing Company',
            slug: 'choosing-a-penetration-testing-company',
          },
          {
            title: 'CREST-Certified Penetration Testing',
            slug: 'crest-certified-pentesting-services',
          },
          {
            title: 'SOC2 Type II Accredited Penetration Testing',
            slug: 'soc-2-certification',
          },
        ],
      },
      penetrationtesting: {
        title: 'Penetration Testing',
        data: [
          {
            title: 'What is Penetration Testing?',
            slug: 'what-is-penetration-testing-2',
          },
          {
            title: 'Purpose of a Pentest',
            slug: 'purpose-of-a-penetration-test',
          },
          {
            title: 'Prevent Ransomware',
            slug: 'pentesting-helps-prevent-ransomware-attacks',
          },
          {
            title: 'Benefits Through Testing',
            slug: '6-benefits-of-using-an-expert-pentesting-company',
          },
          {
            title: 'Cyber Insurance Renewals',
            slug: 'the-top-three-requirements-for-cyber-insurance-renewals',
          },
          {
            title: 'Grey-box Penetration Testing',
            slug: 'grey-box-pentest',
          },
          {
            title: 'Packetlabs Top 10 Vulnerabilities',
            slug: 'top-10-critical-security-patches',
          },
          {
            title: 'Phishing for Security vs Awareness',
            slug: 'phishing-for-security',
          },
        ],
      },
      owasp: {
        title: 'OWASP',
        data: [
          {
            title: 'OWASP Top 10',
            slug: 'owasp-top-10-security',
          },
          {
            title: 'OWASP Mobile Top 10',
            slug: 'owasp-mobile-security-top-10',
          },
          {
            title: 'OWASP Cloud Top 10',
            slug: 'cloud-security',
          },
          {
            title: 'OWASP API Top 10',
            slug: 'owasp-api-security-top-10',
          },
        ],
      },
      cybersecurity: {
        title: 'Cyber Security',
        data: [
          {
            title: 'Cybersecurity Statistics',
            slug: '239-cybersecurity-statistics-2023',
          },
          {
            title: 'Encryption, Encoding and Hashing',
            slug: 'encryption-encoding-and-hashing',
          },
          {
            title: 'Data Classification',
            slug: 'data-classification',
          },
          {
            title: 'Cryptography Attacks',
            slug: 'cryptography-attacks',
          },
          {
            title: 'Dynamic Pages',
            slug: 'dynamic-pages',
          },
        ],
      },
    },
    resources: {
      service: {
        title: 'Service Methodologies',
        data: [
          {
            title: 'Penetration Testing',
            url: 'https://contact.packetlabs.net/penetration-testing-methodology-download',
          },
          {
            title: 'OBPT',
            url: 'https://contact.packetlabs.net/obpt-methodology-download',
          },
          {
            title: 'Red Teaming',
            url: 'https://contact.packetlabs.net/red-teaming-methodology',
          },
          {
            title: 'Application Security Testing',
            url: 'https://contact.packetlabs.net/appsec-pentesting-methodology',
          },
          {
            title: 'ICS/OT',
            url: 'https://contact.packetlabs.net/ot-cyber-security-methodology',
          },
        ],
      },
      sample: {
        title: 'Sample Reports',
        data: [
          {
            title: 'Ransomware Penetration Testing',
            url: 'https://contact.packetlabs.net/ransomware-pentest-sample-report',
          },
          {
            title: 'Infrastructure Penetration Testing',
            url: 'https://contact.packetlabs.net/infrastructure-penetration-test-sample-report',
          },
          {
            title: 'Cloud Penetration Testing',
            url: 'https://contact.packetlabs.net/cloud-penetration-testing-sample-report',
          },
          {
            title: 'Objective-Based Penetration Testing',
            url: 'https://contact.packetlabs.net/objective-based-penetration-testing-report-download',
          },
          {
            title: 'Application Security Testing',
            url: 'https://contact.packetlabs.net/application-security-testing-sample-report',
          },
          {
            title: 'Cyber Maturity Assessment',
            url: 'https://contact.packetlabs.net/cyber-maturity-assessment-sample-report-download',
          },
        ],
      },
      tools: {
        title: 'Tools and Brochures',
        data: [
          {
            title: 'Ransomware Prevention & Response Checklist',
            url: 'https://contact.packetlabs.net/ransomware-checklist',
          },
          {
            title: 'Service Brochure',
            url: 'https://20040397.fs1.hubspotusercontent-na1.net/hubfs/20040397/Packetlabs%20Services%20Brochure.pdf',
          },
        ],
      },
    },
    caseStudies: [
      {
        title: 'SickKids Case Study',
        slug: 'sick-kids',
      },
      {
        title: 'ICES Case Study',
        slug: 'ices-case-study',
      },
    ],
    why: [
      {
        title: 'Careers',
        subheading: 'Start Your Career at Packetlabs',
        slug: '',
      },
    ],
  },
  roles: [
    {
      roleTitle: 'IT Professional',
      slug: 'it-professional',
    },
    {
      roleTitle: 'Security Program Leader/CISO',
      slug: 'security-program-leader',
    },
    {
      roleTitle: 'Procurement',
      slug: 'procurement',
    },
    {
      roleTitle: 'Cybersecurity for Executives',
      slug: 'executive',
    },
    {
      roleTitle: 'Managed Service Provider (MSP)',
      slug: 'managed-service-provider',
    },
    {
      roleTitle: 'App Development Agency',
      slug: 'app-development-agency',
    },
    {
      roleTitle: 'Lawyer',
      slug: 'lawyer',
    },
  ],
  industries: [
    {
      industryName: 'Retail & Ecommerce',
      slug: 'retail-ecommerce',
    },
    {
      industryName: 'Finance',
      slug: 'financial',
    },
    {
      industryName: 'Government',
      slug: 'government',
    },
    {
      industryName: 'Education',
      slug: 'education',
    },
    {
      industryName: 'Technology',
      slug: 'technology',
    },
    {
      industryName: 'Healthcare',
      slug: 'healthcare',
    },
    {
      industryName: 'Utilities and Energy',
      slug: 'utilities-energy',
    },
  ],
  services: {
    securityAssessment: [
      {
        serviceName: 'Cyber Maturity Assessment',
        serviceSubheading: 'A cybersecurity health check for your organization',
        slug: 'cyber-maturity-assessment',
      },
      {
        serviceName: 'ICS/OT Cyber Security Assessment',
        serviceSubheading:
          'Secure your industrial networks, devices, and production lines',
        slug: 'ics-ot-cyber-security-assessment',
      },
      {
        serviceName: 'CIS Benchmark Audit',
        serviceSubheading:
          'Ensure compliance with CIS best practices with a CIS Benchmark Audit',
        slug: 'cis-benchmark-audit',
      },
    ],
    penetrationTesting: [
      {
        serviceName: 'Infrastructure Penetration Testing',
        serviceSubheading: 'Find weaknesses others overlook',
        slug: 'infrastructure-penetration-testing',
      },
      {
        serviceName: 'Ransomware Penetration Testing',
        serviceSubheading:
          'Evaluate your preparedness and risk of a ransomware attack',
        slug: 'ransomware-penetration-testing',
      },
      {
        serviceName: 'Objective-Based Penetration Testing',
        serviceSubheading: 'Simulate real-world, covert, goal-oriented attacks',
        slug: 'objective-based-penetration-testing',
      },
      {
        serviceName: 'Cloud Penetration Testing',
        serviceSubheading:
          'Go beyond the checkbox with Cloud Security Services',
        slug: 'cloud-penetration-testing',
      },
    ],
    adversarySimulation: [
      {
        serviceName: 'Purple Teaming',
        serviceSubheading:
          'Assess your cybersecurity team’s defensive response',
        slug: 'purple-teaming',
      },
      {
        serviceName: 'Red Teaming',
        serviceSubheading: 'Assess potential attack paths',
        slug: 'red-teaming',
      },
    ],
    applicationSecurity: [
      {
        serviceName: 'Application Penetration Testing',
        serviceSubheading: 'Web, Mobile and API Penetration Testing Services',
        slug: 'application-security-testing',
      },
      {
        serviceName: 'DevSecOps',
        serviceSubheading:
          'Discover vulnerabilities in your development lifecycle',
        slug: 'devsecops',
      },
    ],
  },
}

import React from 'react'
import { Link } from 'gatsby'

export type NavItemProps = {
  slug: string
  title: string
  subheading?: string
  basePath?: string
}

const NavItem: React.FC<NavItemProps> = props => {
  return (
    <li>
      <Link to={`${props.basePath ?? ''}/${props.slug ?? ''}`}>
        <p className="title">{props.title}</p>
        {props.subheading && (
          <aside>
            <p className="aside">{props.subheading}</p>
          </aside>
        )}
      </Link>
    </li>
  )
}

export const NavItemList: React.FC<{
  basePath: string
  list: NavItemProps[]
}> = ({ basePath, list }) => {
  const items = list.map((data, index) => (
    <NavItem key={index} basePath={basePath} {...data} />
  ))
  return <>{items}</>
}

export default NavItem
